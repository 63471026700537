<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :height="tabHeight"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :popupParam="popupParam"
          @changeStatus="changeStatus"
          :count="count"
          :is="tab.component"
          @closePopup="closePopup"
          @setRegInfo="setRegInfo"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'education-curriculumn-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'educationCurriculumInfo',
      tabItems: [],
      addTabItems: [],
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  methods: {
    init() {
      this.tabItems = [
        // 교육과정 기본정보
        { name: 'educationCurriculumInfo', icon: 'info', label: 'LBL0002807', component: () => import(`${'./educationCurriculumInfo.vue'}`) }
      ]
      this.addTabItems = [
        // { name: 'educationTargetUser', icon: 'face', label: '교육 대상자', component: () => import(`${'./educationTargetUser.vue'}`) },
        // 기본 교육대상자
        { name: 'educationUser', icon: 'face', label: 'LBL0002808', component: () => import(`${'./educationUser.vue'}`) },
        // 교육교재
        { name: 'educationMaterial', icon: 'file_copy', label: 'LBL0002809', component: () => import(`${'./educationMaterial.vue'}`) },
        // 교육과정별 교육계획 및 결과
        { name: 'educationHistory', icon: 'history', label: 'LBL0002810', component: () => import(`${'./educationHistory.vue'}`) },
        // { name: 'educationMultiPlan', icon: 'add_circle', label: '교육계획 및 대상자 일괄 추가', component: () => import(`${'./educationMultiPlan.vue'}`) },
      ]
      if (this.popupParam.eduCourseId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    tabClick() {
      this.count++;
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>
